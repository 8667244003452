import type { tag } from "@fscrypto/domain";
import { Button, Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from "@fscrypto/ui";
import { Link, NavLink, useMatch, useParams } from "@remix-run/react";
import { orderBy } from "lodash-es";
import config from "~/utils/config";
import { tracking } from "~/utils/tracking";

interface ProjectFilterProps {
  onChange?: (v: string) => void;
  projectName?: string;
  projects: tag.Tag[];
}

export const ProjectFilterSelect = ({ onChange, projectName, projects }: ProjectFilterProps) => {
  const project = projects.find((o) => o.name === projectName);
  const options = [
    { name: "all", displayName: "All Projects", iconFileName: null } as (typeof projects)[number],
    ...orderBy(projects, "name", "asc"),
  ];
  return (
    <Select data-testid="discover-project-filter" value={project?.name ?? "all"} onValueChange={onChange}>
      <SelectTrigger size="md" className="ml-auto w-full md:w-36">
        <SelectValue placeholder="Project" />
      </SelectTrigger>
      <SelectContent>
        {options.map((o) => (
          <SelectItem key={o.name} value={o.name} className="flex w-full items-center">
            <div className="flex items-center">
              {o.iconFileName ? (
                <img
                  src={config.CLOUDINARY_PROJECT_ICON_PATH + o.iconFileName}
                  className="mr-2 h-5 w-5"
                  alt={o.name}
                  title={o.name}
                />
              ) : undefined}
              {o.displayName}
            </div>
          </SelectItem>
        ))}
      </SelectContent>
    </Select>
  );
};

export const ProjectFilterList = ({ projects, basePath }: { projects: tag.Tag[]; basePath: string }) => {
  const showRemoveFilter = useMatch(basePath + `/:project`);
  let { project } = useParams();

  return (
    <div>
      <div className="flex items-center justify-between pb-4">
        {projects.length > 0 && <h5 className="text-muted-foreground text-sm">Filter by Project</h5>}
        {showRemoveFilter ? (
          <Button asChild variant="ghost" size="xs">
            <Link to={basePath}>Remove filter</Link>
          </Button>
        ) : null}
      </div>
      <div className="flex flex-wrap gap-2">
        {projects.map((p) => (
          <Button
            size="sm"
            variant="secondary"
            className="aria-[current]:bg-primary aria-[current]:text-white"
            asChild
            key={p.name}
          >
            <NavLink
              to={p.name === project ? basePath : basePath + "/" + p.name}
              onClick={() => {
                tracking("filter_dashboard_by_project", "Discover");
              }}
            >
              {p.iconFileName ? (
                <img
                  src={config.CLOUDINARY_PROJECT_ICON_PATH + p.iconFileName}
                  className="mr-2 h-5 w-5"
                  alt={p.name}
                  title={p.name}
                />
              ) : undefined}
              {p.name}
            </NavLink>
          </Button>
        ))}
      </div>
    </div>
  );
};
